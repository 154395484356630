export default class Cookies {
  /*
    Vérifie si un élément existe à chaque x ms pendant x ms
    - Selector: Classe ou id de l'élément
    - Callback: Fonction à exécuter si l'élément est trouvé
    - CheckFrequencyInMs: Fréquence des checks à chaque x millisecondes
    - timeoutInMs: Temps en millisecondes après lequel la fonction arrêtera de chercher l'élément même s'il n'est pas trouvé
  */
  static waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
    const startTimeInMs = Date.now();
    (function loopSearch() {
      if (document.querySelector(selector)) {
        callback(); // Callback qui exécute le code dans la variable
      } else {
        setTimeout(() => {
          if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs) {
            return;
          }
          loopSearch(); // Loop récursif
        }, checkFrequencyInMs);
      }
    }());
  }

  // Clear les cookies des catégories désactivés (Domaine interne seulement)
  static updateCookies(reload = true) {
    let cookies = document.cookie.split('; ');

    if (cookies.some((str) => str.includes('cc_cookie_byscuit'))) {
      // eslint-disable-next-line no-undef
      const cookieCategories = cc.getAllBlocks(); // Toutes les catégories
      // eslint-disable-next-line no-undef
      const acceptedCats = cc.getUserPreferences().accepted_categories; // Catégories activés

      cookieCategories.forEach((category) => {
        // Si la catégorie à des cookies et qu'elle est activé par le user
        if (category.cookie_table && acceptedCats.includes(category.toggle.value)) {
          // Retire le cookie de la liste de cookies à effacer
          category.cookie_table.forEach((cookie) => {
            cookies = cookies.filter((name) => !name.includes(cookie.COL1));
          });
        }
      });
    }

    // Loop sur chaque cookies du sites qui ne sont pas dans des catégories de Byscuit activés
    cookies.forEach((cookie) => {
      const url = window.location.hostname.split('.'); // Split le domaine dans un array à chaque .

      // Loop pour essayer tous les domaines que les cookies peuvent utiliser. Ex: dev.villemontlaurier.qc.ca -> villemontlaurier.qc.ca -> qc.ca -> ca
      while (url.length > 0) {
        // On redéfinit la date d'expiration du cookie au 1 Janvier 1970 pour qu'il se fasse supprimer
        const expiredCookie = `${encodeURIComponent(cookie.split(';')[0].split('=')[0])}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=${url.join('.')} ;path=/`;
        document.cookie = expiredCookie;

        const path = window.location.pathname.split('/'); // On split le path actuel dans un array à chaque /

        // Loop pour essayer tous les paths que les cookies peuvent utiliser
        while (path.length > 0) {
          document.cookie = expiredCookie + path.join('/'); // Rajoute le path au cookie pour être sûr de supprimer tous les cookies
          path.pop(); // Pop le path pour essayer la combinaison suivante
        }

        url.shift(); // Shift le domaine pour essayer la combinaison suivante
      }
    });

    if (reload) {
      // Reload la page pour que Byscuit supprime les cookies externes des catégories désactivées
      window.location.reload();
    }
  }
}
