import Cookies from '../classes/cookies';

// Quand Byscuit est init on ajoute les eventlistener pour updater les cookies
export function manageCookies() {
  Cookies.waitForElementToDisplay('#s-rall-bn', () => {
    Cookies.updateCookies(false);

    // Boutons accepter, refuser et sauvegarder dans la sidebar
    document.querySelector('#s-all-bn').addEventListener('click', Cookies.updateCookies);
    document.querySelector('#s-rall-bn').addEventListener('click', Cookies.updateCookies);
    document.querySelector('#s-sv-bn').addEventListener('click', Cookies.updateCookies);

    // Si le cookie cc_cookie_byscuit existe les boutons #c-t-bn et #c-p-bn sont pas init
    const btnDeclinePopup = document.querySelector('#c-t-bn');
    if (btnDeclinePopup) {
      // Boutons accepter et refuser dans le popup
      document.querySelector('#c-p-bn').addEventListener('click', Cookies.updateCookies);
      btnDeclinePopup.addEventListener('click', Cookies.updateCookies);
    }

    // Récupère les paramètres d'URL afin d'afficher le gestionnaire de cookies
    const urlParams = new URLSearchParams(window.location.search);
    const cookieParam = urlParams.get('afficher-parametres-temoins');

    // Si le paramètre dans l'URL est "1" ou "vrai", ouvre le gestionnaire de cookies
    if (cookieParam === '1' || cookieParam === 'vrai') {
      cc.showSettings(0);

      // Nettoie l'URL sans recharger la page, en supprimant le paramètre
      urlParams.delete('afficher-parametres-temoins');

      // Met à jour l'URL pour enlever le paramètre de cookies
      const newUrl = window.location.pathname + (urlParams.toString() ? `?${urlParams.toString()}` : '');
      window.history.replaceState(null, '', newUrl);
    }
  }, 1000, 10000);
}
