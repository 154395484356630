import $ from 'jquery'
import { OBSERVER } from './../main.js'
import anime from 'animejs/lib/anime.es.js'
import { getElementOffset, isMobile } from './helper.js'
import { Accordions } from '../classes/Accordions.js'

// Fonction ajoutant l'évènement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (options) => {
  options.duration === undefined ? options.duration = 800                   : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'          : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart'      : options.easing
  options.offset   === undefined ? options.offset   = 0                     : options.offset

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
  })

  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: options.selector,
    function: onClick
  })

  OBSERVER.on('scrollToBlock')
}


// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (options = {}) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
  const element = typeof unknownElement === 'string' ? document.querySelector(options.scrollTo) : options.scrollTo

  const animation = anime.timeline({
    targets: scrollbar,
    duration: options.duration,
    easing: options.easing
  }).add({ scrollTop: getElementOffset({ element: element }).top + options.offset })

  return animation.finished
}

export function scrollToBlockOffset() {
  const header = document.querySelector('.header')
  const alert = document.querySelector('[data-swiper="alert-container"]')
  let alertHeight = 0

  if(typeof(alert) != 'undefined' && alert != null) {
    alertHeight = alert.offsetHeight
  }

  let headerHeight = header.offsetHeight
  let offset = 0

  const total = () => {
    offset = (headerHeight + alertHeight) * -1
  }

  total()

  const onResize = () => {
    headerHeight = header.offsetHeight

    total()
  }

  const onCloseAlerts = () => {
    alertHeight = 0

    total()
  }

  OBSERVER.add({
    name: 'scrollTo',
    event: 'resize',
    function: onResize
  })

  OBSERVER.add({
    name: 'scrollTo',
    event: 'onCloseAlerts',
    function: onCloseAlerts
  })

  OBSERVER.on('scrollTo')

  return offset
}


// Permet de créer un calendrier
export function calendar() {
  if (!document.querySelector('.calendar')){ //s'il n'y a pas d'events
    return
  }

  let eventsDatesList = document.querySelector('.calendar').dataset.list
  let currentDate = document.querySelector('.calendar').dataset.date

  $.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: 'Aujourd\'hui',
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0
  }

  let datesEnabled = eventsDatesList.split(',')

  $('.calendar').datepicker({
    language: 'fr',
    maxViewMode: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: function (date) { // Rendre seulement les dates de la liste d'évènements disponibles
      let allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
      if(datesEnabled.indexOf(allDates) != -1) return true; else return false
    }
  }).on('changeDate', function(e) {
    let theTimestamp =  Date.parse(e.date)/1000 //Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: {dateFilter: theTimestamp},
      // update: {'evenementlist::eventslist':'#eventsWrapper'},
      // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
      complete: function (data) { data.then(function(data){ Barba.Pjax.goTo('/evenements/1/' + data['date'])})},
    })
    //Fermer l'overlay quand on clique
    $('#overlayCalendar .wrapper a.close span.x').trigger('click')
  })

  $('.calendar').datepicker('update', currentDate)

  addZero()
}

function addZero() {
  getDate()

  const targetNode = document.querySelector('.datepicker-days .table-condensed tbody')
  const config = { attributes: true, childList: true, subtree: true, characterData: true }
  const callback = function() {
    getDate()

    observer.disconnect()
    observer.observe(targetNode, config)
  }

  const observer = new MutationObserver(callback)
  observer.observe(targetNode, config)

  function getDate() {
    let dates = document.querySelectorAll('.day')
    let date

    for(date of dates) {

      if( date.innerHTML < 10) {
        let newDate = '0' + date.innerHTML
        date.innerHTML = newDate
      }
    }
  }
}


// Copier du texte
export function copyTextToClipboard(text) {
  let pos = $(document).scrollTop()

  let textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}


// Ajoute un target blank sur tous les liens externes et PDF
// Empêche une transition si la destination est la page actuelle
export function forceBlankOnExterneAndPdfLinks() {
  let i
  const anchors = document.querySelectorAll('a[href]')
  for (i=0; i<anchors.length; i++) {
    if (anchors[i].target != '_blank' && anchors[i].href != 'javascript:;') {
      if (window.location.hostname !== anchors[i].hostname || anchors[i].href.match('\\.pdf$') || window.location.protocol !== anchors[i].protocol)
        anchors[i].setAttribute('target', '_blank')
    }
  }
}


// Ajoute les metas pour le contenu og
export function ogContent(data) {
  let ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1]
  let ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1]
  let ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1]
  let ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1]
  document.querySelector('meta[property="og:url"]').setAttribute('content',ogUrl)
  document.querySelector('meta[property="og:image"]').setAttribute('content',ogImage)
  document.querySelector('meta[property="og:title"]').setAttribute('content',ogTitle)
  document.querySelector('meta[property="og:description"]').setAttribute('content',ogDescription)
}


// Permet d'aller au backend
export function backendKeyBind() {
  $('body').keydown(function (e) {
    let keycode = (e.keyCode ? e.keyCode : e.which)
    if ((e.ctrlKey && keycode == 13) || (e.metaKey && keycode == 13)) {
      location = '/administration'
      return false
    }
  })
}

export function photoCredits() {
  let hoverItems = document.querySelectorAll('.photo-credits__hover-item')

  if(!isMobile()) {
    const hoverIn = e => {
      e.currentTarget.nextElementSibling.classList.add('visible-credits')
    }
    const hoverOut = e => {
      e.currentTarget.nextElementSibling.classList.remove('visible-credits')
    }

    OBSERVER.add({
      name: 'hover',
      event: 'mouseenter',
      target: hoverItems,
      function: hoverIn
    })
    OBSERVER.add({
      name: 'hover',
      event: 'mouseleave',
      target: hoverItems,
      function: hoverOut
    })
    OBSERVER.on('hover')
  } else if(isMobile()) {
    OBSERVER.add({
      name: 'touch',
      event: 'click',
      target: hoverItems,
      function: touch
    })
    OBSERVER.on('touch')
  }

  function touch(e) {
    let isCreditsVisible = e.currentTarget.nextElementSibling.classList.contains('visible-credits')
    if(isCreditsVisible) {
      e.currentTarget.nextElementSibling.classList.remove('visible-credits')
    } else {
      e.currentTarget.nextElementSibling.classList.add('visible-credits')
    }
  }
}

export function isPageTopVisible() {
  let headerContainer = document.querySelector('.header__container')

  function checkIfElementIsVisible() {
    let pageTop = document.querySelector('.layout__title')

    if(isElementVisible(pageTop)) {
      headerContainer.classList.remove('dropshadow')
    } else if(isElementVisible(pageTop) != true) {
      headerContainer.classList.add('dropshadow')
    }
  }

  function isElementVisible(el) {
    const rect = el.getBoundingClientRect()
    const headerHeight = document.querySelector('.header').offsetHeight

    return (
      rect.bottom - headerHeight >= 0 &&
      rect.right >= 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  checkIfElementIsVisible()

  OBSERVER.add({
    name: 'visible',
    event: 'scroll',
    function: checkIfElementIsVisible
  })
  OBSERVER.add({
    name: 'visible',
    event: 'resize',
    function: checkIfElementIsVisible
  })
  OBSERVER.on('visible')
}

export function getSideHeight() {
  let side = document.querySelector('.side-section__container')
  let sideChild
  let height

  getHeight()

  function getHeight() {
    sideChild = side.children[0]
    height = sideChild.offsetHeight
    document.documentElement.style.setProperty('--side-height', `${height + 30}px`)
  }

  OBSERVER.add({
    name: 'getHeight',
    event: 'resize',
    function: getHeight
  })
  OBSERVER.on('getHeight')
}

export function rubrics() {
  const rubric = new Accordions()
  const header = document.querySelector('.header')
  const alert = document.querySelector('[data-swiper="alert-container"]')
  let alertHeight = 0

  if(typeof(alert) != 'undefined' && alert != null) {
    alertHeight = alert.offsetHeight
  }

  let headerHeight = header.offsetHeight
  let offset = 0

  const total = () => {
    offset = (headerHeight + alertHeight) * -1
    rubric.offset = offset
  }

  total()

  const onResize = () => {
    headerHeight = header.offsetHeight

    total()
  }

  const onCloseAlerts = () => {
    alertHeight = 0

    total()
  }

  OBSERVER.add({
    name: 'rubrics',
    event: 'resize',
    function: onResize
  })

  OBSERVER.add({
    name: 'rubrics',
    event: 'onCloseAlerts',
    function: onCloseAlerts
  })

  OBSERVER.on('rubrics')
}

export function changeSelect(select) {
  $('.dropdown-option').on('click', function() {
    $(select).trigger('change')
  })
}
