import { OBSERVER } from './../main.js'
import Overlay from '../vendor/overlay.js'
import ShareButtons from 'share-buttons/dist/share-buttons.js'

export function overlayShare() {
  new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-share',
        close: '.close-overlay',
      }
    },
    options: {
      speed: 800,
    }
  }).init()

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  // Message de confirmation de copie de l'URL ----------------
  function confirmClipboardCopy() {
    alert('L’adresse URL a bien été copiée dans votre presse-papier.')
  }
  OBSERVER.add({
    name: 'confirmClipboardCopy',
    event: 'click',
    target: '.js-share-copy',
    function: confirmClipboardCopy
  })
  OBSERVER.on('confirmClipboardCopy')

  // Gestion de l'ouverture de l'overlay ----------------------
  function openShareOverlay(e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, '#' + e.currentTarget.dataset.rubric)
    }
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href)
    ShareButtons.update()
  }
  OBSERVER.add({
    name: 'openShareOverlay',
    event: 'click',
    target: '.js-share',
    function: openShareOverlay
  })
  OBSERVER.on('openShareOverlay')

  // Gestion de la fermeture de l'overlay ---------------------
  function closeShareOverlay() {
    window.history.replaceState(null, null, ' ')
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href)
    ShareButtons.update()
  }
  OBSERVER.add({
    name: 'closeShareOverlay',
    event: 'click',
    target: '.overlay-share__background, .overlay-share__close',
    function: closeShareOverlay
  })
  OBSERVER.on('closeShareOverlay')

}

export function overlaySearch() {
  new Overlay({
    name: 'search',
    events: {
      openComplete: true
    },
    create: { close: false },
    click: {
      buttons: {
        toggle: '[data-js="toggle-search"]',
        close: '[data-js="close-search"]',
        switch: '[data-js="trigger-overlay-dropdown"], [data-js="toggle-overlay-menu"], .js-share'
      }
    },
    options: {
      speed: 800,
      closeOnResize: false,
    }
  }).init()
  
  const onFocus = () => { document.getElementById('inputFocus').focus() }
  OBSERVER.add({ name:'overlaySearch', event: 'onOpenCompleteOverlaySearch', function: onFocus })
  OBSERVER.on('overlaySearch')
}

export function overlayDropdown() {
  new Overlay({
    name: 'dropdown',
    click: {
      buttons: {  
        trigger: '[data-js="trigger-overlay-dropdown"]',
        close: '[data-js="close-overlay-dropdown"], a[href]',
        switch: '.js-share, [data-js="toggle-overlay-menu"], [data-js="toggle-search"]'
      }
    },
    animations: {
      selector: '.overlay-dropdown__content',
      styles: [{ 
        property: 'height', 
        value: 'dataset', 
        easing: 'easeInOutQuart' 
      }]
    },
    options: { 
      speed: 1000
    }
  }).init()

  // Ajuster la taille de chaque sous-section pour éviter les bugs lorsque la fenêtre est redimensionnée
  const setHeightSubSections = () => {
    let i, j, offsetHeight = 0, height = 0
    const sections = document.querySelectorAll('.js-dropdown-section')
    const sectionLength = sections.length
  
    for (i=0; i<sectionLength; i++) {
      const subSection = sections[i].children
      const subSectionLength = subSection.length
      
      for (j=0; j<subSectionLength; j++) {
        subSection[j].style.height = ''
        offsetHeight = subSection[j].offsetHeight
        if (offsetHeight > height)
          height = offsetHeight
      }
  
      for (j=0; j<subSectionLength; j++) 
        subSection[j].style.height = `${height}px`
  
      height = 0
      offsetHeight = 0
    }
  }

  setHeightSubSections()

  OBSERVER.add({ name: 'setHeightSubSections', event: 'resize', function: setHeightSubSections, target: 'window' })
  OBSERVER.on('setHeightSubSections')
}

export function overlayMenu() {
  let menu = new Overlay({
    name: 'menu',
    click: {
      buttons: {
        trigger: '[data-js="trigger-overlay-menu"]',
        close: '[data-js="close-overlay-menu"], a[href]',
        switch: '[data-js="toggle-overlay-search"], [data-js="toggle-overlay-fast-links"]'
      }
    },
    options: {
      speed: 600,
    }
  })

  menu.init()
}

// Fonction gérant l'overlay de calendrier
export const overlayCalendar = () => {
  let calendar = new Overlay({
    name: 'calendar',
    events: {
      close: true
    },
    create: { close: false },
    click: {
      buttons: {
        toggle: '[data-js="toggle-calendar"]',
        close: '[data-js="close-calendar"]',
        switch: '[data-js="trigger-overlay-dropdown"], [data-js="toggle-overlay-menu"], [data-js="toggle-overlay-fast-links"], .js-share'
      }
    },
    options: {
      speed: 800,
      closeOnResize: false,
    }
  })
  
  calendar.init()
}

// Fonction gérant l'overlay du popup d'alerte
export function overlayPopup() {
  new Overlay({
    name: 'popup',
    click: {
      buttons: {
        close: '.js-close-overlay-popup',
      }
    },
    timeout: {
      delay: 2000
    },
    options: {
      speed: 600,
    }
  }).init()
}