import Swiper from 'swiper/bundle'
import { OBSERVER } from './../main.js'

// Swiper de la bannière
export function swiperHomeBanner() {
  // Si il y a plus qu'une slide
  if (document.querySelectorAll('[data-swiper="home-banner-slide"]').length > 1) {

    // Création du slider
    window['home-banner'] = new Swiper('[data-swiper="home-banner"]', {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 7000,
      },
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: '[data-swiper="home-banner-pagination"]',
        type: 'bullets',
        clickable: true
      },
    })
    window["home-banner"].on('slideChange', function () {
      let photoCredits = document.querySelector('.visible-credits')
      if(photoCredits) {
        photoCredits.classList.remove('visible-credits')
      }
    })
  }
}

// Swiper de la vitrine de bannière
export function swiperHomeBannerShowcase() {
  // Si il y a plus qu'une slide
  if (document.querySelectorAll('[data-swiper="home-banner-showcase-slide"]').length > 1) {

    // Création du slider
    window['home-banner__showcase'] = new Swiper('[data-swiper="home-banner-showcase"]', {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 7000,
      },
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      navigation: {
        prevEl: '[data-swiper="home-banner-showcase-prev"]',
        nextEl: '[data-swiper="home-banner-showcase-next"]'
      },
    })
  }
}

// Swiper de la vitrine sous la bannière (En mobile)
export function swiperHomeShowcase() {
  // Si il y a plus qu'une slide
  if (document.querySelectorAll('[data-swiper="home-showcase-slide"]').length > 1) {

    // Création du slider
    window['home-banner__showcase'] = new Swiper('[data-swiper="home-showcase"]', {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 7000,
      },
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      navigation: {
        prevEl: '[data-swiper="home-showcase-prev"]',
        nextEl: '[data-swiper="home-showcase-next"]'
      },
    })
  }
}

// Swiper des actualités sur l'accueil
export function swiperHomeNews() {

  // Création du slider
  window['home-news'] = new Swiper('[data-swiper="home-news"]', {
    loop: true,
    speed: 700,
    autoplay: {
      delay: 7000,
    },
    slidesPerView: "auto",
    loopedSlides: 3,
    centeredSlides: true,
    pagination: {
      el: '[data-swiper="home-news-pagination"]',
      type: 'bullets',
      clickable: true
    },
  })
}

// Swiper des liens rapide
export function swiperFastLinks() {
  let settings = {
    loop: true,
    speed: 500,
    autoplay: {
      delay: 7000,
    },
    slidesPerView: 'auto',
    loopedSlides: 3,
    navigation: {
      prevEl: '[data-swiper="fast-links-prev"]',
      nextEl: '[data-swiper="fast-links-next"]'
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        loopedSlides: 0,
      },
      1000: {
        slidesPerView: 3,
        loopedSlides: 0,
      }
    }
  }

  // Création du slider
  window['fast-links'] = new Swiper('[data-swiper="fast-links"]', settings)

  swiperInitialization()

  function swiperInitialization() {
    let pageWidth = window.innerWidth
    let slidesLength = document.querySelectorAll('[data-swiper="fast-links-slide"]').length
    let duplicateSlidesLength =  document.querySelectorAll('.fast-links__swiper-wrapper .swiper-slide-duplicate').length
    let reelSlidesLength = slidesLength - duplicateSlidesLength

    if(reelSlidesLength <= 3 &&  pageWidth > 1000) {
      window["fast-links"].disable()
      hideArrows()
    } else if(reelSlidesLength <= 3  &&  pageWidth <= 1000) {
        window['fast-links'].enable()
        visibleArrows()
    } else if(reelSlidesLength > 3  &&  pageWidth > 1000) {
        window['fast-links'].enable()
        visibleArrows()
    } else if(reelSlidesLength > 3  &&  pageWidth <= 1000) {
        window['fast-links'].enable()
        visibleArrows()
    }
  }

  function visibleArrows() { 
    let arrows = document.querySelectorAll('.fast-links__arrow')
    let i
    for(i=0; i<arrows.length; i++) {
      let arrow = arrows[i]
      arrow.classList.add('visible-arrow') 
    }
  }

  function hideArrows() {
    let arrows = document.querySelectorAll('.fast-links__arrow')
    let i
    for(i=0; i<arrows.length; i++) {
      let arrow = arrows[i]
      arrow.classList.remove('visible-arrow') 
    }
  }

  OBSERVER.add({
    name: 'swiperInitialization',
    event: 'resize',
    function: swiperInitialization
  })
  OBSERVER.on('swiperInitialization')
}
