import barba from '@barba/core'
import LazyLoad from 'vanilla-lazyload'
import { SCROLLFIRE, OBSERVER } from './main.js'
import { defaultTransition } from './transitions/default-transition.js'
import { backendKeyBind, calendar, clickToScrollToBlock, getSideHeight, isPageTopVisible, ogContent, photoCredits, rubrics, scrollToBlockOffset, changeSelect } from './functions/functions.js'
import { dynamicPackage } from './functions/dynamic.js'
import { resetDropzone, formsPackage, tailSelect, clearInput, inputsAndTextareaLabel } from './functions/form.js'
import { overlayCalendar, overlayDropdown, overlayMenu, overlaySearch, overlayShare, overlayPopup  } from './functions/overlays.js'
import { Accordions } from './classes/Accordions.js'
import Overlay from './vendor/overlay.js'
import { formContact, formExample, formJobs, formSuccess, formInfolettre } from './functions/validation.js'
import { masksPackage } from './functions/masks.js'
import { hundredVH, removeHoverOnMobile, sizeTitle } from './functions/helper.js'
import { swiperFastLinks, swiperHomeBanner, swiperHomeBannerShowcase, swiperHomeNews, swiperHomeShowcase } from './functions/sliders.js'
import { swiperAlert } from './functions/alert.js'
import { manageCookies } from './functions/manageCookies';

window.formSuccess = formSuccess
window.resetDropzone = resetDropzone

export const initBarba = () => {

  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null)
      current.container.remove()
      overlayShare()
      overlaySearch()
      overlayDropdown()
      overlayMenu()
      overlayPopup()
      swiperAlert()
      backendKeyBind()
      removeHoverOnMobile()
      sizeTitle({
        selectors: '.page-title__title',
        caract01: 50,
        caract02: 60,
        caract03: 70,
      })
      isPageTopVisible()
      hundredVH()
      swiperFastLinks()
      inputsAndTextareaLabel()
      clearInput()
      formInfolettre('#form-newsletter','#form-newsletter-submit')
      clickToScrollToBlock({ selector: '.js-back-to-top', scrollTo: 'html', offset: scrollToBlockOffset() })
      manageCookies();

      if ((window.location.href.indexOf("riviere-rouge.ca") > -1) || (window.location.href.indexOf("riviere-rouge.dev2.blankodev.com") > -1)){
        document.dispatchEvent(new Event('DOMContentLoaded'))
      }
  })

  barba.hooks.enter ((data) => {
    ogContent(data)
  })

  barba.hooks.afterLeave (() => {
    OBSERVER.allOff()
    OBSERVER.removeAll()
    Overlay.destroyAll()
    SCROLLFIRE.destroy()
  })

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [
      defaultTransition()
    ],

    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          rubrics()
          formsPackage()
          masksPackage()
          dynamicPackage()
          formExample()
          photoCredits()
          getSideHeight()
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          swiperHomeBanner()
          swiperHomeBannerShowcase()
          swiperHomeShowcase()
          swiperHomeNews()
          photoCredits()
          sizeTitle({
            selectors: '.home-banner__title',
            caract01: 50,
            caract02: 60,
            caract03: 70,
          })
          clickToScrollToBlock({ selector: '[data-js="scroll-to-news"]', scrollTo: '[data-js="news"]', offset: scrollToBlockOffset() })
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'newsDetailsView',
        beforeEnter() {
          dynamicPackage()
          photoCredits()
          getSideHeight()
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'newsListView',
        beforeEnter() {
          tailSelect()
          getSideHeight()
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'eventsDetailsView',
        beforeEnter() {
          dynamicPackage()
          photoCredits()
          getSideHeight()
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'eventsListView',
        beforeEnter() {
          tailSelect()
          getSideHeight()
          calendar()
          overlayCalendar()
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          rubrics()
          formsPackage()
          masksPackage()
          formContact()
          getSideHeight()
          clickToScrollToBlock({ selector: '[data-js="scroll-to-form"]', scrollTo: '[data-js="form"]', offset: scrollToBlockOffset() })
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'searchResultsView',
        beforeEnter() {
          getSideHeight()
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'jobsDetailsView',
        beforeEnter() {
          formsPackage()
          masksPackage()
          dynamicPackage()
          formJobs()
          getSideHeight()
          clickToScrollToBlock({ selector: '[data-js="scroll-to-form"]', scrollTo: '[data-js="form"]', offset: scrollToBlockOffset() })
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'jobsListView',
        beforeEnter() {
          formsPackage()
          masksPackage()
          dynamicPackage()
          photoCredits()
          formJobs()
          getSideHeight()
          changeSelect('#form-filter-select')
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'errorsView',
        beforeEnter() {
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
    ]
  })
}
